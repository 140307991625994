
// import firebase from "firebase/app";
// require("firebase/functions");

// const listUsers = firebase.functions().httpsCallable("listUsers");
// listUsers().then((result) => console.log(result));

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  name: "Folder",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
};
